import { useState, createContext, useContext } from 'react'

export interface JumpToLink {
  blockId: string
  blockName: string
}

interface JumpToLinksContextValue {
  jumpToLinks: JumpToLink[]
  addJumpToLink: (link: JumpToLink) => void
  scrollToSection: (link: JumpToLink) => void
}

const JumpToLinksContext = createContext<JumpToLinksContextValue>({
  jumpToLinks: [],
  addJumpToLink: () => {},
  scrollToSection: () => {},
})

export function JumpToLinksProvider({ children }) {
  const [jumpLinks, setJumpLinks] = useState<JumpToLink[]>([])

  const addJumpToLink = (link) => {
    setJumpLinks((prevLinks) => [...prevLinks, link])
  }

  const scrollToSection = (link) => {
    const element = document.getElementById(link.blockId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <JumpToLinksContext.Provider value={{ jumpToLinks: jumpLinks, addJumpToLink, scrollToSection }}>
      {children}
    </JumpToLinksContext.Provider>
  )
}

export function useJumpToLinksContext() {
  return useContext(JumpToLinksContext)
}
