import { isWeb } from '@my/ui'
import { MicrositeRestQueryOutput } from 'app/features/microsites/screens/microsite/queries/use-microsite-rest.query'
import React, { useLayoutEffect } from 'react'
import Script from 'next/script'

type IFrameContextProps = {
  microsite?: MicrositeRestQueryOutput
  isIframe: boolean
}

export type IFrameContextProviderProps = {
  children: React.ReactNode
  microsite?: MicrositeRestQueryOutput
}

export const defaultValues: IFrameContextProps = {
  microsite: undefined,
  isIframe: false,
}

export const IFrameContext = React.createContext<IFrameContextProps>(defaultValues)

export function useIFrame() {
  return React.useContext(IFrameContext)
}

export const IFrameProvider = ({ microsite, children }: IFrameContextProviderProps) => {
  const [micrositeState, setMicrositeState] = React.useState<MicrositeRestQueryOutput | undefined>(
    microsite
  )
  const [loading, setLoading] = React.useState<boolean>(true)
  const [isInIframe, setIsInIframe] = React.useState<boolean>(!!microsite)

  React.useEffect(() => {
    if (!microsite) {
      return
    }

    setMicrositeState(microsite)
  }, [microsite])

  useLayoutEffect(() => {
    // Only check if we're in an iframe on the web
    if (isWeb) {
      // Check if we're in an iframe after the initial render
      if (typeof window !== 'undefined' && window.location !== window.parent.location) {
        setIsInIframe(true)
      }
    }
    setLoading(false)
  }, [])

  return (
    <IFrameContext.Provider
      value={{
        microsite: micrositeState,
        isIframe: isInIframe,
      }}
    >
      <>
        {!loading && !isInIframe && (
          <>
            <Script
              src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
              type="text/javascript"
              strategy="lazyOnload"
              data-domain-script="11050c01-fb1a-4a90-a7e2-3547e5d92cd0"
            />
            <Script id="cookie-pro" type="text/javascript" strategy="lazyOnload">
              {`function OptanonWrapper() { }`}
            </Script>
          </>
        )}

        {children}
      </>
    </IFrameContext.Provider>
  )
}
