import React, { createContext, useContext } from 'react';

export interface EventBusContextValue {
  events: Record<string, ((...args: any[]) => void)[]>;
  on: (eventName: string, callback: (...args: any[]) => void) => void;
  off: (eventName: string, callback: (...args: any[]) => void) => void;
  emit: (eventName: string, ...args: any[]) => void;
  emitOnce: (eventName: string, ...args: any[]) => void;
}

const EventBusContext = createContext<EventBusContextValue>({
  events: {},
  on: () => {},
  off: () => {},
  emit: () => {},
  emitOnce: () => {},
});

export const useEventBus = () => {
  return useContext(EventBusContext);
};

export const EventBus: EventBusContextValue = {
  events: {},
  on: (eventName: string, callback: (...args: any[]) => void) => {
    if (!EventBus.events[eventName]) {
      EventBus.events[eventName] = [];
    }
    EventBus.events[eventName]!.push(callback);
  },
  off: (eventName: string, callback: (...args: any[]) => void) => {
    if (!EventBus.events[eventName]) {
      return;
    }
    EventBus.events[eventName] = EventBus.events[eventName]!.filter(
      (eventCallback) => callback !== eventCallback
    );
  },
  emit: (eventName: string, ...args: any[]) => {
    if (!EventBus.events[eventName]) {
      return;
    }
    EventBus.events[eventName]!.forEach((callback) => callback(...args));
  },
  emitOnce: (eventName: string, ...args: any[]) => {
    if (!EventBus.events[eventName]) {
      return;
    }
    EventBus.events[eventName]!.forEach((callback) => callback(...args));
    EventBus.events[eventName] = [];
  }
};

export const EventBusProvider = ({ children }) => {


  return (
    <EventBusContext.Provider value={EventBus}>
      {children}
    </EventBusContext.Provider>
  );
};
