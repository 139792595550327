import { createTokens } from "tamagui";
import { colors } from "./colors";
import { radius, size, space, zIndex } from "@tamagui/themes";

// We are using default tokens from Tamagui:
// https://tamagui.dev/docs/intro/tokens
export const tokens = createTokens({
  color: colors,
  radius,
  zIndex,
  space,
  size,
})