import crypto from 'crypto'

export const objectToHash = <T>(object: T, idKey: keyof T): string => {
  return [
    object[idKey]?.toString(),
    crypto
      .createHash('sha1')
      .update(JSON.stringify(object))
      .digest('hex')
  ].join(':')
}
