import { EnvConfig } from 'app/config/config'
// import merge from 'lodash.merge'

import { getEnv } from 'app/lib/env-getter'

const devUrl = process.env.DEV_URL || 'http://localhost:8000'

const raw: EnvConfig = {
  global: {
    appEnv: 'development',
    clientId: 'supersaviour',
    cmsUrl: devUrl + '/api/graphql',
    cmsBaseUrl: devUrl,
    siteUrl: 'http://localhost:3000', // Change this when testing sharing on Facebook
    webCmsUrl: '/api/graphql',
    urlsPerSitemap: 10,
    preRelease: false,
    amplitudeApiKey: '',
    microsoftClarityProjectId: '',
    oneSignalAppId: '',
    googleMeasurementId: '',
    sentryDsn: '',
  },
  staging: {
    appEnv: 'staging',
    cmsUrl: 'https://cms-staging.studentsaviour.com/api/graphql',
    cmsBaseUrl: 'http://cms-staging:8000',
    siteUrl: 'https://app-staging-hf19v93f.studentsaviour.com',
    preRelease: false,
    microsoftClarityProjectId: 'ig2m78vct3',
    sentryDsn: '',
  },
  loadtest: {
    appEnv: 'loadtest',
    cmsUrl: 'https://p01--cms-loadtest--qwrjjj7sczmd.pbzbsqs6ws.code.run/api/graphql',
    cmsBaseUrl: 'http://cms-loadtest:8000',
    siteUrl: 'https://p01--app-loadtest--qwrjjj7sczmd.pbzbsqs6ws.code.run',
    preRelease: false,
    microsoftClarityProjectId: '',
  },
  production: {
    appEnv: 'production',
    cmsUrl: 'https://cms.studentsaviour.com/api/graphql',
    cmsBaseUrl: 'https://cms.studentsaviour.com',
    siteUrl: 'https://www.studentsaviour.com',
    urlsPerSitemap: 1000,
    preRelease: true,
    amplitudeApiKey: 'cff8375d5b0267a1d4e467aa4ac5b462',
    microsoftClarityProjectId: 'hvn0xehny1',
    googleMeasurementId: 'G-7FP918LDPG',
    sentryDsn: '',
  },
}

// const config = merge(raw.global, raw[getEnv()] || {})
const config = {
  ...raw.global,
  ...raw[getEnv()] || {},
}

export default config
