import type { Types } from '@amplitude/analytics-browser'
import { useEffect, useRef } from 'react'
import config from 'app/config'

const useAmplitude = () => {
  const amplitudeRef = useRef<null | Types.BrowserClient>()
  useEffect(() => {
    const initAmplitude = async () => {
      if (config.amplitudeApiKey) {
        // @ts-ignore - nextjs handles dynamic imports
        amplitudeRef.current = await import('@amplitude/analytics-browser')
        amplitudeRef.current.init(config.amplitudeApiKey, undefined, {
          defaultTracking: {
            sessions: true,
          },
        })
      }
    }
    initAmplitude()
  }, [])
  return amplitudeRef.current
}

export { useAmplitude }
