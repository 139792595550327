import { User } from "app/types"
import React from "react"

export type AuthUser = User & {
  token: string
}

type AuthContextProps = {
  user: AuthUser | null
  logIn: (user: AuthUser) => Promise<void>
  logOut: () => Promise<void>
  updateUser: (user: Partial<AuthUser>) => Promise<void>
}

export type AuthContextProviderProps = {
  children: React.ReactNode
  user?: AuthUser | null
}

export const defaultValues: AuthContextProps = {
  user: null,
  logIn: () => Promise.resolve(),
  logOut: () => Promise.resolve(),
  updateUser: (user: Partial<AuthUser>) => Promise.resolve()
}

export const AuthContext = React.createContext<AuthContextProps>(defaultValues)

export function useAuth() {
  return React.useContext(AuthContext)
}