import { UserFeedQueryOutput } from "../screens/groups-feed-screen/queries"

export const feedMergePolicy = {
  getUserFeed: {
    keyArgs: ['sort'],
    merge(existing: UserFeedQueryOutput['getUserFeed'], incoming: UserFeedQueryOutput['getUserFeed']) {
      if (!existing) {
        return incoming
      }

      const { docs: existingDocs, ads: existingAds = [], ...existingRest } = existing
      const { docs: incomingDocs, ads: incomingAds, ...incomingRest } = incoming

      // remove any items from incoming that are already in existing
      const docsSet = new Set(existingDocs.map((doc: any) => doc.__ref))
      const docs = [...existingDocs]

      const adsSet = new Set(existingAds.map((ad: any) => ad.__ref))
      const ads = [...existingAds]

      for (const doc of incomingDocs as any[]) {
        if (!docsSet.has(doc.__ref)) {
          docsSet.add(doc.__ref)
          docs.push(doc)
        }
      }

      for (const ad of incomingAds as any[]) {
        if (!adsSet.has(ad.__ref)) {
          adsSet.add(ad.__ref)
          ads.push(ad)
        }
      }

      return {
        ...existingRest,
        ...incomingRest,
        page: existing.page > incoming.page ? existing.page : incoming.page,
        docs,
        ads,
      }
    }
  }
}
