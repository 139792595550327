import { InMemoryCacheConfig, defaultDataIdFromObject } from '@apollo/client'
import {
  groupReportedPostsMergePolicy,
  groupMembersMergePolicy,
} from 'app/features/group-admin/policies'
import {
  feedMergePolicy,
  groupPostsMergePolicy,
  groupSearchMergePolicy,
  postCommentsMergePolicy,
  myGroupPostsMergePolicy,
} from 'app/features/group/policies'
import { userChatsMergePolicy, chatMessagesMergePolicy } from 'app/features/chats/policies'
import { notificationsMergePolicy } from 'app/features/notifications/policies'
import { offersMergePolicy } from 'app/features/offer/policies'
import {
  searchArticlesMergePolicy,
  searchBrandsMergePolicy,
  searchOffersMergePolicy,
} from 'app/features/search/policies'
import { objectToHash } from 'app/utils/object-to-hash'

export const apolloCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        ...offersMergePolicy,
        ...feedMergePolicy,
        ...groupPostsMergePolicy,
        ...groupSearchMergePolicy,
        ...postCommentsMergePolicy,
        ...notificationsMergePolicy,
        ...searchBrandsMergePolicy,
        ...searchArticlesMergePolicy,
        ...searchOffersMergePolicy,
        ...groupMembersMergePolicy,
        ...groupReportedPostsMergePolicy,
        ...userChatsMergePolicy,
        ...chatMessagesMergePolicy,
        ...myGroupPostsMergePolicy,
      },
    },
  },
  dataIdFromObject: (object) => {
    /**
     *! This is to help when dealing with blocks that have the same id
     */
    if (Object.hasOwn(object, 'id')) {
      if (Object.hasOwn(object, 'blockType')) {
        return objectToHash(object, 'id')
      }

      if (['Accordian_Entries', 'ColumnLayout_Columns', 'FeaturedContentCarousel_Items'].includes(object.__typename || '')) {
        return objectToHash(object, 'id')
      }
    }

    return defaultDataIdFromObject(object)
  }
}
