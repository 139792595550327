import { createFont, createTamagui } from 'tamagui'
import { shorthands } from '@tamagui/shorthands'
import { createMedia } from '@tamagui/react-native-media-driver'
import { animations } from './animations'
import { themes } from './themes'
import { tokens } from './tokens'

const fontConfig = {
  weight: {
    true: 400,
  },
  size: {
    '0.5': 10,
    '1': 12,
    '2': 13,
    '3': 14,
    '4': 15,
    '4.5': 16,
    '5': 18,
    '6': 20,
    '7': 22,
    '7.5': 24,
    '8': 25,
    '8.5': 30,
    '9': 33,
    '9.5': 36,
    '10': 51,
    '11': 61,
    '12': 68,
    '13': 79,
    '14': 101,
    '15': 125,
    '16': 147,
    true: 15,
  },
  lineHeight: {
    '1': 18,
    '2': 19,
    '3': 20,
    '4.5': 21,
    '4': 22,
    '5': 25,
    '6': 27,
    '7': 30,
    '7.5': 32,
    '8': 34,
    '8.5': 38,
    '9': 46,
    '9.5': 46,
    '10': 66,
    '11': 77,
    '12': 85,
    '13': 97,
    '14': 121,
    '15': 148,
    '16': 172,
    true: 22,
  },
  letterSpacing: {
    true: 0
  },
}

const headingFont = createFont(
  {
    family: 'var(--font-poppins, Poppins)',
    face: {
      300: { normal: 'Poppins_300Light' },
      400: { normal: 'Poppins_400Regular', italic: 'Poppins_400Regular_Italic' },
      500: { normal: 'Poppins_500Medium' },
      600: { normal: 'Poppins_600SemiBold' },
      700: { normal: 'Poppins_700Bold' },
    },
    ...fontConfig,
  },
)

const bodyFont = createFont(
  {
    family: 'var(--font-poppins, Poppins)',
    face: {
      300: { normal: 'Poppins_300Light' },
      400: { normal: 'Poppins_400Regular', italic: 'Poppins_400Regular_Italic' },
      500: { normal: 'Poppins_500Medium' },
      600: { normal: 'Poppins_600SemiBold' },
      700: { normal: 'Poppins_700Bold' },
    },
    ...fontConfig,
  },
)

export const config = createTamagui({
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  shorthands,
  fonts: {
    heading: headingFont,
    body: bodyFont,
  },
  themes,
  tokens,
  media: createMedia({
    xss: { maxWidth: 420 },
    xs: { maxWidth: 576 },
    sm: { maxWidth: 768 },
    md: { maxWidth: 992 },
    lg: { maxWidth: 1200 },
    xl: { maxWidth: 1440 },
    xxl: { maxWidth: 1600 },
    gtXss: { minWidth: 420 + 1 },
    gtXs: { minWidth: 576 + 1 },
    gtSm: { minWidth: 768 + 1 },
    gtMd: { minWidth: 992 + 1 },
    gtLg: { minWidth: 1200 + 1 },
    gtXl: { minWidth: 1440 + 1 },
    short: { maxHeight: 820 },
    tall: { minHeight: 820 },
    hoverNone: { hover: 'none' },
    pointerCoarse: { pointer: 'coarse' },
  }),
})
