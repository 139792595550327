import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
interface TrackedScrollPosition {
  [key: string]: {
    position: number
    timestamp: number
  }
}

export const useTrackedScroll = () => {
  const router = useRouter()

  const handleRouteChange = useCallback(async () => {
    const currentPath = router.asPath
    const prevPositions = JSON.parse(
      localStorage.getItem('scrollPositions') || '{}'
    ) as TrackedScrollPosition

    // Set the positions but make sure to only keep the two latest paths
    const newPositions = {
      ...prevPositions,
      [currentPath]: {
        position: window.scrollY,
        timestamp: Date.now(),
      },
    }

    const paths = Object.keys(newPositions)
    if (paths.length > 2) {
      const oldestPath = paths.reduce((oldestPath, path) => {
        if (newPositions[path].timestamp < newPositions[oldestPath].timestamp) {
          return path
        }
        return oldestPath
      }, paths[0])
      delete newPositions[oldestPath]
    }

    // Save scroll position to localStorage
    localStorage.setItem('scrollPositions', JSON.stringify(newPositions))
  }, [router.asPath])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [handleRouteChange, router.events])

  // Restore scroll position from localStorage
  useEffect(() => {
    const restoreScrollPosition = async () => {
      const savedScrollPositions = JSON.parse(
        localStorage.getItem('scrollPositions') || '{}'
      ) as TrackedScrollPosition
      window.scrollTo(0, savedScrollPositions[router.asPath]?.position || 0)
    }

    // Restore scroll position on route change
    router.events.on('routeChangeComplete', restoreScrollPosition)
    return () => {
      router.events.off('routeChangeComplete', restoreScrollPosition)
    }
  }, [router])
}
