export const FB_PIXEL_ID = "2649068492001353"

declare global {
  interface Window {
    fbq: (event: string, ...args: any[]) => void
  }
}

export const pageview = () => {
  window.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  window.fbq('track', name, options)
}