import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { createApolloClient } from "app/lib/create-apollo-client";
import { createApolloCache } from "app/lib/apollo-cache.web";
import { useState } from "react";
import merge from "deepmerge";
import isEqual from "lodash.isequal";

// Apollo client SSR setup
// Reference: https://github.com/apollographql/next-apollo-example

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

type UseApolloProps = {
  pageProps: any;
  token?: string;
  previewToken?: string;
};

const mergeCache = (existingCache: NormalizedCacheObject, incomingCache: NormalizedCacheObject) => {
  const merged = merge(existingCache || {}, incomingCache || {}, {
    arrayMerge: (destinationArray, sourceArray) => [
      ...sourceArray,
      ...destinationArray.filter((d) =>
        sourceArray.every((s) => !isEqual(d, s))
      ),
    ],
  });

  return merged;
};


// Creates apollo client
export const useApollo = (props: UseApolloProps) => {
  const state = props.pageProps[APOLLO_STATE_PROP_NAME];

  const [store, setStore] = useState<ApolloClient<NormalizedCacheObject>>();

  if (!store) {
    setStore(initializeApollo({
      initialState: state,
      token: props.token,
      previewToken: props.previewToken,
    }));
  }

  if (state && store) {
    const data = mergeCache(store.cache.extract(), state);

    store.restore(data);
  }

  return store;
}


// Injects Apollo SSR cache into pageProps
export const addApolloState = (client: ApolloClient<NormalizedCacheObject>, pageProps: any) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

type InitializeApolloOptions = {
  initialState?: any;
  token?: string;
  previewToken?: string;
};

let apolloClient: ApolloClient<NormalizedCacheObject> | null;

// Extracts apollo cache passed via pageProps and restores it on the client side
export function initializeApollo(options: InitializeApolloOptions = {}) {
  const _apolloClient = apolloClient ?? createApolloClient({
    token: options.token,
    previewToken: options.previewToken,
    cache: createApolloCache(),
  });

  if (options.initialState) {
    const existingCache = _apolloClient.cache.extract();
    const data = mergeCache(options.initialState, existingCache);
    _apolloClient.cache.restore(data);
  }

  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}
