import { AuthUser } from "app/provider/auth"

export const saveCredentials = async (user: AuthUser) => {
  await fetch('/api/auth/create-session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
    credentials: 'include',
  })
}