import { NextSeo } from 'next-seo'
import config from '../../../config'

const seoConfig = {
  title: 'Student Saviour',
  fullTitle: 'Student Discounts, Uni Groups & Advice',
  description:
    'FREE Student Discount Codes & Deals on 1000’s of your favourite brands. Join the millions of students saving BIG today.',
  themeColor: '#ffffff',
}

export const GlobalMeta = () => {
  const { title, fullTitle, description, themeColor } = seoConfig
  const optional: { [key: string]: string } = {
    'application-name': fullTitle,
    'theme-color': themeColor,
  }

  return (
    <NextSeo
      titleTemplate={`%s | ${title}`}
      twitter={{
        cardType: 'summary_large_image',
      }}
      title={fullTitle}
      description={description}
      openGraph={{
        type: 'website',
        title: fullTitle,
        description: description,
        site_name: title,
        images: [
          {
            url: '/og-image.png',
            width: 1200,
            height: 630,
            alt: 'Student Saviour',
          },
        ],
      }}
      additionalLinkTags={[
        // ...[512, 192].map((size) => ({
        //   rel: 'apple-touch-icon',
        //   href: `/assets/meta/apple-touch-icon-${size}.png`,
        //   type: 'image/png',
        //   sizes: `${size}x${size}`,
        // })),
        // ...[16, 32].map((size) => ({
        //   rel: 'icon',
        //   type: 'image/png',
        //   sizes: `${size}x${size}`,
        //   href: `/assets/meta/favicon-${size}.png`,
        // })),
        {
          rel: 'shortcut icon',
          href: '/favicon.ico',
        },
        // {
        //   rel: 'manifest',
        //   href: '/manifest.json',
        // },
      ]}
      additionalMetaTags={[
        ...(Object.keys(optional).map((name) => {
          return {
            property: name,
            content: optional[name],
          }
        }) as any),
      ]}
      nofollow={config.appEnv !== 'production'}
      noindex={config.appEnv !== 'production'}
    />
  )
}

