import { ReportedPostsQueryOutput } from "../queries"

export const groupReportedPostsMergePolicy = {
  getGroupPostReportsList: {
    keyArgs: ['groupId', 'searchTerm', 'sort'],
    merge(existing: ReportedPostsQueryOutput['getGroupPostReportsList'], incoming: ReportedPostsQueryOutput['getGroupPostReportsList']) {
      if (!existing) {
        return incoming
      }

      const { docs: existingDocs, ...existingRest } = existing
      const { docs: incomingDocs, ...incomingRest } = incoming

      // remove any items from incoming that are already in existing
      const docsSet = new Set(existingDocs.map((doc: any) => doc.__ref))
      const docs = [...existingDocs]

      for (const doc of incomingDocs as any[]) {
        if (!docsSet.has(doc.__ref)) {
          docsSet.add(doc.__ref)
          docs.push(doc)
        }
      }

      return {
        ...existingRest,
        ...incomingRest,
        page: existing.page > incoming.page ? existing.page : incoming.page,
        docs,
      }
    }
  }
}
