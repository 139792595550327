import '@tamagui/core/reset.css'
import '../styles/global.css'
import 'raf/polyfill'
import * as fbq from '../lib/fbpixel'
import { isWebTouchable } from '@my/ui'
import { ApolloProvider } from 'app/provider/apollo'
import { NextThemeProvider } from '@tamagui/next-theme'
import { Provider } from 'app/provider'
import React, { useEffect } from 'react'
import type { SolitoAppProps } from 'solito'
import config from 'app/config'
import { AuthContextProvider } from 'app/provider/auth/auth-context'
import { EventBusProvider } from 'app/provider/event-bus'
import { IFrameProvider } from 'app/provider/iframe'
import { AuthUser } from 'app/provider/auth'
import { useApollo } from 'lib/initialize-apollo'
import { useAmplitude } from 'app/hooks/use-amplitude.web'
import { JumpToLinksProvider } from 'app/features/shared/contexts'
import { GlobalMeta } from 'app/components'
import Head from 'next/head'
import { useTrackedScroll } from 'hooks/use-tracked-scroll'
import { Poppins } from 'next/font/google'
import Script from 'next/script'

type AppProps = {
  user: AuthUser | null
}

const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  style: ['normal', 'italic'],
  preload: true,
  display: 'swap',
  subsets: ['latin', 'latin-ext'],
  adjustFontFallback: true,
  variable: '--font-poppins',
})

function MyApp({ Component, pageProps, router }: SolitoAppProps<AppProps>) {
  const { user, microsite } = pageProps
  const previewToken =
    router?.query?.preview && router?.query?.token ? (router?.query?.token as string) : ''
  const client = useApollo({ pageProps, token: user?.token, previewToken })
  const amplitude = useAmplitude()
  useTrackedScroll()

  useEffect(() => {
    const handleRouteChange = function () {
      fbq.pageview()
    }

    amplitude?.setUserId(user?.email)
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [user, amplitude, router])

  // Disable zoom in when focusing on inputs
  const viewport = `width=device-width, initial-scale=1, viewport-fit=cover${
    isWebTouchable ? ', maximum-scale=1' : ''
  }`

  return (
    <>
      <Head>
        <meta key="viewport" name="viewport" content={viewport} />
        {/* Microsoft Clarity */}
        {config.microsoftClarityProjectId && (
          <Script
            id="microsoft-clarity"
            dangerouslySetInnerHTML={{
              __html: `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "${config.microsoftClarityProjectId}");
              `,
            }}
          />
        )}
      </Head>
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      {config.googleMeasurementId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${config.googleMeasurementId}`}
          />
          <Script id="google-analytics">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${config.googleMeasurementId}');
            `}
          </Script>
        </>
      )}
      <Script id="add-font-to-root">
        {`
          document.body.classList.add('${poppins.variable}');
        `}
      </Script>
      <GlobalMeta />
      <EventBusProvider>
        <ApolloProvider client={client}>
          <AuthContextProvider user={user}>
            <ThemeProvider>
              <JumpToLinksProvider>
                <IFrameProvider microsite={microsite}>
                  <div className={poppins.variable}>
                    <Component {...pageProps} />
                  </div>
                </IFrameProvider>
              </JumpToLinksProvider>
            </ThemeProvider>
          </AuthContextProvider>
        </ApolloProvider>
      </EventBusProvider>
    </>
  )
}

function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <NextThemeProvider forcedTheme="light">
      <Provider disableRootThemeClass defaultTheme="light">
        {children}
      </Provider>
    </NextThemeProvider>
  )
}

export default MyApp
