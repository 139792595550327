// TODO: Remove and use 'gray' instead
const greyColors = {
  grey1: '#fafafa',
  grey2: '#f5f5f5',
  grey3: '#eeeeee',
  grey4: '#e0e0e0',
  grey5: '#bdbdbd',
  grey6: '#9e9e9e',
  grey7: '#757575',
  grey8: '#616161',
  grey9: '#424242',
  grey10: '#212121',
  grey12: '#f9f9f9',
  grey11: '#343434',
}

const redColors = {
  red6: '#FF6369',
  red7: '#ff4442',
  red8: '#c62828',
  red9: '#b71c1c',
  red10: '#b91c1c',
  red11: '#CD2B31',
}

const whiteColors = {
  white1: 'rgba(255, 255, 255, 0.00)',
  white2: 'rgba(255, 255, 255, 0.02)',
  white3: 'rgba(255, 255, 255, 0.03)',
  white4: 'rgba(255, 255, 255, 0.06)',
  white5: 'rgba(255, 255, 255, 0.09)',
  white6: 'rgba(255, 255, 255, 0.124)',
  white7: 'rgba(255, 255, 255, 0.18)',
  white8: 'rgba(255, 255, 255, 0.25)',
  white9: 'rgba(255, 255, 255, 0.39)',
  white10: 'rgba(255, 255, 255, 0.45)',
  white11: 'rgba(255, 255, 255, 0.59)',
  white12: 'rgba(255, 255, 255, 0.92)',
  white13: '#ffffff'
}

const tealColors = {
  teal5: '#0AC9B1',
}

const greenColors = {
  green4: '#42FF4A',
  green8: '#2e7d32',
}

const overlayColors = {
  overlay0: 'rgba(0,0,0,0)',
  overlay1: 'rgba(0,0,0,0.01)',
  overlay2: 'rgba(0, 0, 0, 0.027)',
  overlay3: 'rgba(0, 0, 0, 0.04)',
  overlay4: 'rgba(0, 0, 0, 0.07)',
  overlay6: 'rgba(0, 0, 0, 0.11)',
  overlay8: 'rgba(0, 0, 0, 0.22)',
  overlay9: 'rgba(0, 0, 0, 0.44)',
  overlay10: 'rgba(0, 0, 0, 0.48)',
  overlay11: 'rgba(0, 0, 0, 0.56)',
  overlay12: 'rgba(0, 0, 0, 0.91)',
  overlay13: 'rgba(0,0,0,0.8)',
}

const grayColors = {
  gray1: '#FCFCFC',
  gray2: '#F8F8F8',
  gray3: '#F3F3F3',
  gray4: '#EDEDED',
  gray5: '#E8E8E8',
  gray6: '#E2E2E2',
  gray7: '#DBDBDB',
  gray8: '#C7C7C7',
  gray9: '#8F8F8F',
  gray10: '#858585',
  gray11: '#6F6F6F',
  gray12: '#171717',
  gray13: '#343434',
}

const primaryColors = {
  primary1: '#FBFFFF',
  primary2: '#F1FFFF',
  primary3: '#D6FFFF',
  primary4: '#BFFFFF',
  primary5: '#68FFFF',
  primary6: '#00FFFF', // Primary
  primary7: '#06F8F8',
  primary8: '#02EAEA',
  primary9: '#04DDDD',
  primary10: '#05C8C8',
  primary11: '#08BDBD',
  primary12: '#03A3A3',
}

export const colors = {
  ...greyColors,
  ...grayColors,
  ...redColors,
  ...greenColors,
  ...overlayColors,
  ...tealColors,
  ...primaryColors,
  ...whiteColors,
  primary: primaryColors.primary6,
  white: whiteColors.white13,
  black: '#000000',
  offBlack: '#343434',
  offWhite: '#f9f9f9',
  backdrop: 'rgba(0, 0, 0, 0.5)',
  transparent: 'transparent',
}
