import { QueryHookOptions, gql, useQuery } from '@apollo/client'
import { AuthUser } from 'app/provider/auth'

const query = gql`
  query getUserProfile($id: String!) {
    User(id: $id) {
      id
      fullName
      email
      avatar {
        id
        sizes {
          small {
            url
          }
          medium {
            url
          }
        }
      }
      dateOfBirth
      gender
      subjectOfStudy {
        id
        name
      }
      yearOfStudy
      university {
        id
        name
      }
      universityEmail
      universityEmailVerified
      referralUrl
      userType
      createdAt
    }
  }
`

export interface UserProfileQueryInput {
  id: string
}

export interface UserProfileQueryOutput {
  User: {
    id: string
    fullName: string
    email: string
    avatar?: {
      id: string
      sizes: {
        small: {
          url: string
        }
        medium: {
          url: string
        }
      }
    }
    dateOfBirth: string
    gender: string
    subjectOfStudy?: {
      id: string
      name: string
    }
    yearOfStudy: string
    university?: {
      id: string
      name: string
    }
    universityEmail: string
    universityEmailVerified: boolean
    referralUrl: string
    userType: string
    createdAt: string
  }
}

export const useUserProfile = (
  input: UserProfileQueryInput,
  options?: QueryHookOptions<UserProfileQueryOutput, UserProfileQueryInput>
) =>
  useQuery<UserProfileQueryOutput, UserProfileQueryInput>(query, {
    variables: input,
    ...options,
  })

export const asAuthUser = (user: UserProfileQueryOutput['User'], existingUser: AuthUser | null) => ({
  ...existingUser,
  id: user.id,
  email: user.email,
  fullName: user.fullName,
  universityEmail: user.universityEmail,
  avatar: {
    small: user.avatar?.sizes?.small.url || '',
    medium: user.avatar?.sizes?.medium.url || '',
  },
  universityEmailVerified: user.universityEmailVerified,
  university: user.university && {
    id: user.university?.id,
    name: user.university?.name,
  },
  yearOfStudy: user.yearOfStudy,
  subjectOfStudy: user.subjectOfStudy && {
    id: user.subjectOfStudy?.id,
    name: user.subjectOfStudy?.name,
  },
  createdAt: new Date(user.createdAt),
  userType: user.userType,
  referralUrl: user.referralUrl,
})
