import React, { useEffect } from 'react'
import { AuthContext, AuthContextProviderProps, AuthUser, defaultValues } from '.'
import { saveCredentials } from 'app/features/auth/commands'
import { useRouter } from 'solito/router'
import { useApolloClient } from '@apollo/client'
import { updateAuthToken } from 'app/lib/create-apollo-client'
import { asAuthUser, useUserProfile } from 'app/features/user/queries'
import { useEventBus } from '../event-bus'

export function AuthContextProvider({ user, children }: AuthContextProviderProps) {
  const apolloClient = useApolloClient()
  const [_user, setUser] = React.useState<AuthUser | null>(user || defaultValues.user)
  const router = useRouter()
  const eventBus = useEventBus()

  const { refetch } = useUserProfile(
    {
      id: _user?.id || '',
    },
    {
      skip: !_user?.id,
      pollInterval: 1000 * 60 * 5, // 5 minutes
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        // If we can't load the user profile, we assume the user is not logged in anymore
        // so we should log them out and flush the cache
        if (!data.User && _user) {
          handleLogOut()
          return
        }
        handleUpdateUser(asAuthUser(data.User, _user))
      },
    }
  )

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch()
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])

  useEffect(() => {
    eventBus.on('logOut', async () => {
      // No need to do anything if the user is already logged out
      if (!_user) {
        return
      }

      await fetch('/api/auth/logout', {
        method: 'POST',
      })
      setUser(null)
      updateAuthToken(null, apolloClient)
      await apolloClient.resetStore()
    })

    return () => eventBus.off('logOut', handleLogOut)
  }, [_user])

  const handleLogIn = async (user: AuthUser) => {
    try {
      await saveCredentials(user)
      updateAuthToken(user.token, apolloClient)
      await apolloClient.resetStore()
      setUser(user)
    } catch (error) {
      console.error(error)
    }
  }

  const handleLogOut = async () => {
    try {
      await fetch('/api/auth/logout', {
        method: 'POST',
      })
      setUser(null)
      updateAuthToken(null, apolloClient)
      await apolloClient.resetStore()
    } catch (error) {
      console.error(error)
    } finally {
      router.replace('/student-discount')
    }
  }

  const handleUpdateUser = async (user: Partial<AuthUser>) => {
    try {
      const updatedUser = {
        ..._user,
        ...user,
      } as AuthUser
      await saveCredentials(updatedUser)
      setUser(updatedUser)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        logIn: (user) => handleLogIn(user),
        logOut: () => handleLogOut(),
        updateUser: (user) => handleUpdateUser(user),
        user: _user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
