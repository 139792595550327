import { ApolloClient, ApolloProvider as _ApolloProvider } from '@apollo/client'

type ApolloProviderProps = {
  children: React.ReactNode
  client: ApolloClient<any>
}

export const ApolloProvider = ({ children, client }: ApolloProviderProps) => {
  return <_ApolloProvider client={client}>{children}</_ApolloProvider>
}
