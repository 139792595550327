export const getEnv = () => {
  let env = ''
  const processEnv = process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV

  if (processEnv === 'production') {
    env = 'production'
  } else if (processEnv === 'staging') {
    env = 'staging'
  } else if (processEnv === 'loadtest') {
    env = 'loadtest'
  }

  return env
}

