import { createTheme } from 'tamagui'
import { colors } from './colors'

const mainTheme = createTheme({
  color: colors.black,
  background: colors.grey1,
  borderColor: colors.black,
  borderColorHover: colors.black,
  borderColorFocus: colors.black,
  borderColorPress: colors.black,
  shadowColor: colors.grey7,
  shadowColorHover: colors.grey7,
  backgroundHover: colors.grey4,
  backgroundFocus: colors.grey5,
  backgroundPress: colors.grey3,
})

const blackTheme = createTheme({
  color: colors.white,
  background: colors.black,
  borderColor: colors.black,
  borderColorHover: colors.black,
  borderColorFocus: colors.black,
  borderColorPress: colors.black,
  shadowColor: colors.black,
  shadowColorHover: colors.black,
  backgroundHover: colors.gray12,
  backgroundFocus: colors.gray12,
  backgroundPress: colors.gray12,
})

const primaryTheme = createTheme({
  color: colors.black,
  background: colors.primary,
  borderColor: colors.primary,
  borderColorHover: colors.primary,
  borderColorFocus: colors.primary,
  borderColorPress: colors.primary,
  shadowColor: colors.primary,
  shadowColorHover: colors.primary,
  backgroundHover: colors.primary5,
  backgroundFocus: colors.primary5,
  backgroundPress: colors.primary5,
})

const primary4Theme = createTheme({
  color: colors.black,
  background: colors.primary4,
  borderColor: colors.primary4,
  borderColorHover: colors.primary3,
  borderColorFocus: colors.primary3,
  borderColorPress: colors.primary3,
  shadowColor: colors.primary3,
  shadowColorHover: colors.primary3,
  backgroundHover: colors.primary3,
  backgroundFocus: colors.primary3,
  backgroundPress: colors.primary3,
})

const grayTheme = createTheme({
  color: colors.black,
  background: colors.gray3,
  borderColor: colors.gray4,
  borderColorHover: colors.gray4,
  borderColorFocus: colors.gray4,
  borderColorPress: colors.gray4,
  shadowColor: colors.gray4,
  shadowColorHover: colors.gray4,
  backgroundHover: colors.gray1,
  backgroundFocus: colors.gray1,
  backgroundPress: colors.gray1,
})

const cyanTheme = createTheme({
  color: colors.primary10,
  background: colors.primary2,
  borderColor: colors.primary10,
  backgroundHover: colors.primary3,
  backgroundFocus: colors.primary3,
  backgroundPress: colors.primary3,
  borderColorHover: colors.primary11,
  borderColorFocus: colors.primary11,
  borderColorPress: colors.primary11,
})

const whiteTheme = createTheme({
  color: colors.black,
  background: colors.white,
  borderColor: colors.white,
  backgroundHover: colors.white12,
  backgroundPress: colors.white12,
  backgroundFocus: colors.white12,
  borderColorHover: colors.white,
  borderColorFocus: colors.white,
  borderColorPress: colors.white,
})

const whiteGray = createTheme({
  color: colors.black,
  background: colors.white13,
  borderColor: colors.white13,
  backgroundHover: colors.gray1,
  backgroundPress: colors.gray1,
  backgroundFocus: colors.gray1,
  borderColorHover: colors.gray1,
  borderColorFocus: colors.gray1,
  borderColorPress: colors.gray1,
})

export const themes = {
  light: mainTheme,
  dark: mainTheme, // Use the same colors for dark mode
  light_black: blackTheme,
  dark_black: blackTheme,
  light_primary: primaryTheme,
  dark_primary: primaryTheme,
  light_primary4: primary4Theme,
  dark_primary4: primary4Theme,
  light_gray: grayTheme,
  dark_gray: grayTheme,
  light_cyan: cyanTheme,
  dark_cyan: cyanTheme,
  light_white: whiteTheme,
  dark_white: whiteTheme,
  light_whiteGray: whiteGray,
  dark_whiteGray: whiteGray,
}
